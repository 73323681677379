<style scoped>
    /* Set the page size to A4 and the orientation to landscape */
    @page {
        size: letter portrait;
    }

    /* Set the font size to 12px for printing */
    @media print {
        body {
            font-size: 12px;
        }

        .page-break {
            page-break-after: always;
            counter-reset: page;
        }

        @page :footer {
            display: none;
        }
    }

    #logo {
        position: absolute;
        top: 2px;
        right: 2px;
        width: 200px;
        height: 100px;
    }

    .title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;
    }

    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    #no-border {
        border: none;
    }

    /* td, th.bordered {
        border: 2px solid #000000;
        text-align: left;
        padding: 8px;
    }

    th.no-border {
        border: none;
    } */

    th.bordered {
        border-top: 1px dashed #000000;
        border-bottom: 1px dashed #000000;
        padding: 8px;
    }

    td.bordered-bottom {
        border-bottom: 1px dashed #000000;
    }

    .remove_border tr th{
        border: none;
    }

    #table-list {
        margin-top: 80px;
    }

    .info {
        margin-top: 20px;
        width: 40%;
        float: left;
        margin-bottom: 20px;
    }

    .space-for-logo {
        width: 200px;
        height: 100px;
    }

    #page-counter {
        display: table-footer-group;
        }
        
    #page-counter:after {
        counter-increment: page;
        content: counter(page) ' of ' counter(page);
    }
</style>

<template>
    <div>
        <div class="page-break" :key="idx" v-for="(data, idx) in datas">
            <table style="width:100%">
                <thead>
                    <tr>
                        <th colspan="9">
                            <div class="title">
                                <p>HANDOVER</p>
                                <p>PT. SINARMAS DISTRIBUSI NUSANTARA</p>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th class="py-0" colspan="2">Cabang</th>
                        <th class="py-0" colspan="2">: {{ data.Warehouse }}</th>
                        <!-- <th class="py-0"></th> -->
                        <!-- <th class="py-0"></th> -->
                        <th class="py-0"></th>
                        <th class="py-0">Print</th>
                        <th class="py-0" colspan="2">: {{ data.PrintCounter }}</th>
                    </tr>
                    <tr>
                        <th class="py-0" colspan="2">Shipment Plan</th>
                        <th class="py-0" colspan="2">: {{ data.TransportPlan.ShipmentNumber }}</th>
                        <!-- <th class="py-0"></th> -->
                        <!-- <th class="py-0"></th> -->
                        <th class="py-0"></th>
                        <th class="py-0">Print date</th>
                        <th class="py-0" colspan="2">: {{ dateNow }}</th>
                    </tr>
                    <tr>
                        <th class="py-0" colspan="2">Tanggal shipment</th>
                        <th class="py-0" colspan="2">: {{ formatDate(data.Date) }}</th>
                        <!-- <th class="py-0"></th> -->
                        <!-- <th class="py-0"></th> -->
                        <th class="py-0"></th>
                        <th class="py-0">Print time</th>
                        <th class="py-0" colspan="2">: {{ timeNow }}</th>
                    </tr>
                    <tr>
                        <th class="py-0" colspan="2">Driver</th>
                        <th class="py-0" colspan="2">: {{ data.TransportPlan.DriverName }}</th>
                        <!-- <th class="py-0"></th> -->
                        <!-- <th class="py-0"></th> -->
                        <th class="py-0"></th>
                        <th class="py-0">Page</th>
                        <th class="py-0" colspan="2">: 1 of 1</th>
                    </tr>
                    <tr>
                        <th class="py-0" colspan="2">Vehicle Number</th>
                        <th class="py-0" colspan="2">: {{ data.TransportPlan.VehicleNumber }}</th>
                        <!-- <th class="py-0"></th> -->
                        <!-- <th class="py-0"></th> -->
                        <th class="py-0"></th>
                        <th class="py-0"></th>
                        <th class="py-0" colspan="2"></th>
                    </tr>
                    <tr>
                        <th class="py-1 bordered" style="vertical-align: middle; text-align:center;">Customer Code</th>
                        <th class="py-1 bordered" style="vertical-align: middle; text-align:center;">Customer Name</th>
                        <!-- <th class="py-1 bordered" style="vertical-align: middle; text-align:center;">Proforma Invoice</th> -->
                        <th class="py-1 bordered" style="vertical-align: middle; text-align:center;">SKU Code</th>
                        <th class="py-1 bordered" style="vertical-align: middle; text-align:center;">SKU Name</th>
                        <th class="py-1 bordered" style="vertical-align: middle; text-align:center;">Batch</th>
                        <th class="py-1 bordered" style="vertical-align: middle; text-align:center;">ED</th>
                        <th class="py-1 bordered" style="vertical-align: middle; text-align:center;">Qty</th>
                        <th class="py-1 bordered" style="vertical-align: middle; text-align:center;">UOM</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(tr, idxtr) in data.table" >
                        <template v-for="(tr2, idxtr2) in tr.proforma" >
                            <tr :key="`${idxtr}-${idxtr2}-${idxtr3}`" v-for="(tr3, idxtr3) in tr2.line">
                                <td class="py-1 px-3" :class="{'bordered-bottom': idxtr+1 == data.table.length && idxtr2+1 == tr.proforma.length}" style="vertical-align: middle;" :rowspan="tr.proforma.reduce((p, c) => p+c.line.length, 0)" v-if="idxtr2 == 0 && idxtr3 == 0">{{ tr.customer_code }}</td>
                                <td class="py-1 px-3" :class="{'bordered-bottom': idxtr+1 == data.table.length && idxtr2+1 == tr.proforma.length}" style="vertical-align: middle;" :rowspan="tr.proforma.reduce((p, c) => p+c.line.length, 0)" v-if="idxtr2 == 0 && idxtr3 == 0">{{ tr.customer_name }}</td>
                                <!-- <td class="py-1 px-3" :class="{'bordered-bottom': idxtr+1 == data.table.length && idxtr2+1 == tr.proforma.length}" style="vertical-align: middle;" :rowspan="tr2.line.length" v-if="idxtr3 == 0">{{ tr2.proforma }}</td> -->
                                <td class="py-1 px-3" :class="{'bordered-bottom': idxtr+1 == data.table.length && idxtr2+1 == tr.proforma.length && idxtr3+1 == tr2.line.length}" style="vertical-align: middle;">{{ tr3.sku_code }}</td>
                                <td class="py-1 px-3" :class="{'bordered-bottom': idxtr+1 == data.table.length && idxtr2+1 == tr.proforma.length && idxtr3+1 == tr2.line.length}" style="vertical-align: middle;">{{ tr3.sku_name }}</td>
                                <td class="py-1 px-3" :class="{'bordered-bottom': idxtr+1 == data.table.length && idxtr2+1 == tr.proforma.length && idxtr3+1 == tr2.line.length}" style="vertical-align: middle;">{{ tr3.batch }}</td>
                                <td class="py-1 px-3" :class="{'bordered-bottom': idxtr+1 == data.table.length && idxtr2+1 == tr.proforma.length && idxtr3+1 == tr2.line.length}" style="vertical-align: middle;">{{ formatDate(tr3.expired_date) }}</td>
                                <td class="py-1 px-3" :class="{'bordered-bottom': idxtr+1 == data.table.length && idxtr2+1 == tr.proforma.length && idxtr3+1 == tr2.line.length}" style="vertical-align: middle;">{{ tr3.qty }}</td>
                                <td class="py-1 px-3" :class="{'bordered-bottom': idxtr+1 == data.table.length && idxtr2+1 == tr.proforma.length && idxtr3+1 == tr2.line.length}" style="vertical-align: middle; text-align:center;">{{ tr3.uom }}</td>
                            </tr>
                        </template>
                    </template>
                </tbody>
            </table>
            <br/>
            <table class="remove_border">
                <tr>
                    <th style="text-align:center;">Diserahkan oleh</th>
                    <th style="text-align:center;">Diterima oleh</th>
                    <th style="text-align:center;">Diketahui oleh</th>
                </tr>
                <tr>
                    <th style="height: 50px;"></th>
                    <th style="height: 50px;"></th>
                    <th style="height: 50px;"></th>
                </tr>
                <tr>
                    <th style="text-align:center;">Checker</th>
                    <th style="text-align:center;">Driver</th>
                    <th style="text-align:center;">SPV Logistic</th>
                </tr>
            </table>
            <!-- <div class="w-full inline-flex justify-center">Cetakan ke {{ data.PrintCounter }}</div> -->
            <!-- <div class="">Cetakan ke {{ data.PrintCounter }}</div> -->
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";
// import logoSDN from "../../../assets/images/logo/logo-sdn.png";
export default {
    name: "PrintPdf",
    // props: {
    //     handover_id: Number
    // },
    data() {
        return {
            id: null,
            handover_ids: [],
            handover_status: 0,
            table: [],
            sto_plan_code: null,
            picking_code: null,
            selectedSrcWarehouse: null,
            selectedDestWarehouse: null,
            reqDeliveryDate: null,
            eta: null,
            // logoSDN: logoSDN,
            showInfo: false,
            // data: {},
            datas: {},
            dateNow: "",
            timeNow: "",
        }
    },
    mounted() {
        var now = moment()
        now.add(1, 'days')

        this.reqDeliveryDate = now.toDate()
        this.eta = now.toDate()

        this.handover_ids = window.opener.handoverIds;
        this.handover_status = window.opener.handoverStatus;
        if (this.handover_ids.length) {
            // this.getData()
            this.print();
        }
    },
    methods: {
        async print() {
            const tempTitle = document.title
            await this.getData()
            await window.print()
            document.title = tempTitle
        },
        async getData() {
            this.$vs.loading();
            // let resp = await this.$http.get(`/api/wms/v1/simple-outbound-planner/handover-data/${this.handover_id}`);
            let resp = await this.$http.get(`/api/wms/v1/simple-outbound-planner/handover-data`, {
                params: {
                    handover_ids: this.handover_ids,
                    handover_status: this.handover_status,
                    is_print: 1,
                }
            });
            if (resp.code == 200) {
                this.datas = resp.data.records.map(data => {
                    const table = []
                    const mapCustomer = {}
                    data.Loading.forEach(d => {
                        let dt = mapCustomer[d.Packing.DeliveryOrder.SalesOrder.CustomerCode]
                        if (!dt) dt = {customer_name: d.Packing.DeliveryOrder.SalesOrder.CustomerName, proforma: []}
                        const proforma = {
                            // proforma: d.Packing.DeliveryOrder.SalesOrder.ProformaInvoice.Code,
                            proforma: d.Packing.DeliveryOrder.Code,
                            line: [],
                        }
                        d.Packing.PackingLine.forEach(dd => {
                            proforma.line.push({
                                sku_code: dd.SkuCode,
                                sku_name: dd.ItemName,
                                batch: dd.Batch,
                                expired_date: dd.ExpiredDate,
                                qty: dd.Amount,
                                uom: dd.ItemUnit,
                            })
                        })
                        dt.proforma.push(proforma)
                        mapCustomer[d.Packing.DeliveryOrder.SalesOrder.CustomerCode] = dt
                    })
                    for (const mc in mapCustomer) {
                        table.push({...mapCustomer[mc], customer_code: mc})
                    }
                    data.table = table
                    return data
                })
                // this.table = resp.data.HandoverLine;
                // this.sto_plan_code = resp.data.headers.sto_plan_code;
                // this.picking_code = resp.data.headers.picking_code;
                // this.selectedDestWarehouse = resp.data.headers.dest_warehouse_code + " - " + resp.data.headers.dest_warehouse_name;
                // this.reqDeliveryDate = resp.data.headers.req_delivery_date;
                // this.eta = resp.data.headers.eta;
                document.title = this.datas.reduce((prev, curr) => `${prev}-${curr.TransportPlan.ShipmentNumber}`, "Handover")
                console.log(this.datas)
                const now = new Date()
                this.dateNow = this.formatDate(now.setHours(now.getHours()+7))
                this.timeNow = this.formatTime(now)
                this.$vs.loading.close();
            } else {
                this.$vs.loading.close();
            }
        },
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                // momentDate.add(1, 'days');              // Tambahkan 1 hari
                return momentDate.format('DD/MM/YYYY');
            };
        },
        formatTime: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('hh:mm:ss');
            };
        },
    },
}
</script>
